import React, { Fragment, useEffect } from "react";
import Header from "./Header";
import { useDispatch, useSelector } from "react-redux";
import {
  handleResetApp,
  handleSetLoginDetails,
} from "../actions/loginDetails/loginDetails";
import { useLocation } from "react-router-dom";
import ColorPanel from "./ColorPanel";  
import {
  handleDiamondDetails,
  handleSettingDetails,
} from "../actions/chooseYourSetting/chooseYourSettingActions";
import Footer from "./footer";
export const Layout = (props) => {
  const dispatch = useDispatch();
  const search = useLocation().search;
  const loginDetails = useSelector((state) => state.loginDetailsReducer);
  const paramDealerId = new URLSearchParams(search).get("DealerLink");
  const isReset = new URLSearchParams(search).get("ResetIFrame");

  useEffect(() => {
    const dealerId = new URLSearchParams(search).get("DealerLink");
    dispatch(handleSetLoginDetails(dealerId));
  }, []);


  useEffect(() => {
    if (isReset == "true") {
      dispatch(handleResetApp());
      dispatch(handleSettingDetails({}));
      dispatch(handleDiamondDetails({}));
      if (paramDealerId) {
        dispatch(handleSetLoginDetails(paramDealerId));
      }
    }
  }, [isReset]);

  useEffect(() => {
    if (loginDetails) {
      if (loginDetails.dealerId) {
        if (loginDetails.dealerId !== paramDealerId) {
          dispatch(handleResetApp());
          dispatch(handleSetLoginDetails(paramDealerId));
        }
      }
    }
  }, [paramDealerId]);

  // need to add the dispatch method for the needed color change
  return (
    <Fragment>
      <div className="form__fields m-2 iframe__block p-0 border-0 bg-transparent">
        <div className="top__navigation">
          <Header />
        </div>

        <div>{props.children}</div>
        <Footer />
        <ColorPanel />
      </div>
    </Fragment>
  );
};

export default Layout;
